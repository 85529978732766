import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';

const NikkeGuidesPrivatyMaid: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Privaty: Unkind Maid guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_priv.jpg"
            alt="Wishlist"
          />
        </div>
        <div className="page-details">
          <h1>Privaty: Unkind Maid guide & review</h1>
          <h2>
            A guide & review for Privaty: Unkind Maid in NIKKE: Goddess of
            Victory.
          </h2>
          <p>
            Last updated: <strong>17/02/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <div className="employee-container for-nikke">
          <NikkeCharacter
            mode="icon"
            slug="privaty-unkind-maid"
            enablePopover
          />
        </div>
        <p>
          Our tsundere princess is now a maid?! An adorable one at that and
          bashful too! Whatever led to this fateful twist, many didn't expect
          that, but it was definitely a most desirable one. As a maid, Privaty
          is quite the reticent soul, and that's also the reason why she only
          works with certain units. In short, she is a DPS B3 unit outfitted
          with an exclusive shotgun, whose accuracy gradually increases with
          each hit, and a repertoire of abilities that heavily rely on hitting
          pellets.
        </p>
        <SectionHeader title="Kit Analysis" />
        <p>
          Privaty: Unkind Maid (PrivM) offers something we've never witnessed
          before: a self-sufficient Shotgun that becomes accurate with each
          successive pellet (not shot, by the way)! Unlike other shotguns, she
          is believed to be able to rival the accuracy of Assault Rifles without
          any Hit Rate buff. In reality, this is not always the case due to its
          limited rate of fire. The accuracy cap is reached the instant she
          fires, but it will have already decreased by the time she fires her
          next shot. Furthermore, this extra advantage also comes with a steep
          cost; that is, her basic attack multiplier is lower than that of
          regular shotguns, clocking in at around 182% per shot. Worry not,
          however, as her skills will make up for that.
        </p>
        <h6>Skill 1</h6>
        <blockquote>
          <p>
            ■ Activates when landing attacks 30 time(s) using pellets. Affects 2
            enemy unit(s) nearest to the crosshair.
          </p>
          <p>Deals 202.84% of final ATK as additional damage.</p>
        </blockquote>
        <p>
          PrivM's Skill 1 deals bonus damage to two enemies closest to the
          crosshair after she lands a total of 30 pellets. When triggering, any
          excess pellet count carries over to the next cycle. This skill damage
          peaks at 202.84%, which is not that substantial against a single
          target, but it constitutes nearly 27% of her basic attack damage and
          grows to 36% with the presence of Leona (in Full Burst). Against two
          or more enemies, this skill doubles in power, but for now there seems
          to be not much value in mobbing with her. It can be handy in Arena,
          however.
        </p>
        <h6>Skill 2</h6>
        <blockquote>
          <p>
            ■ Activates when more than 5 pellet(s) hit with a single normal
            attack. Affects self.
          </p>
          <p>Reloading Speed ▲ 20.88% for 2 sec.</p>
          <p>
            ■ Activates when hitting 30 time(s) using pellets during Full Burst
            Time. Affects self.
          </p>
          <p>Reload 1 round(s).</p>
          <p>ATK ▲ 11.22%, stacks up to 5 time(s) and lasts for 2 sec.</p>
        </blockquote>
        <p>
          An unrewarding skill with troublesome restrictions! Firstly, the
          reload speed value is extremely low. Unless you pair it with SAnis's
          or Privaty's buff, it is meaningless most of the time even at max
          level. Not only that, the ATK buff is difficult to trigger and
          maintain. Requiring 30 pellets per cycle, an unbuffed PrivM takes
          exactly 3 shots (2 seconds) to refresh the stack. The problem is the
          skill also lasts for 2 seconds only, which means she is stuck at 1
          stack forever! This can easily be mitigated with Tove or Leona, who
          provides ATK Speed and Increased Pellet Count respectively.{' '}
          <strong>
            Be warned that she can still instantly lose all stacks whenever she
            reloads for a long time during Full Burst.
          </strong>
        </p>
        <Alert variant="primary">
          <p>
            Either Tove or Leona alone is enough to allow PrivM to increase her
            S2 stacks. However, Tove's ATK Speed is unreliable, whereas Leona's
            buff is simply weaker than Tove's. Ideally, you want to deploy both
            at the same time, as it will also accelerate the stacking process.
          </p>
        </Alert>
        <p>
          When triggering the ATK component, PrivM also restores 1 ammo. This
          amounts to 2x ammo efficiency when coupled with Leona or 1.5x without.
          To put simply, each ammo may contribute to 2 shots. This is the most
          important part of her skill, as it helps her avoid reloading, which in
          turn resets the aforementioned stacks.
        </p>
        <h6>Burst</h6>
        <blockquote>
          <p>■ Affects self.</p>
          <p>Attack Damage ▲ 10.56% for 10 sec.</p>
          <p>Critical Damage ▲ 88.17% for 10 sec.</p>
          <p>■ Affects all enemies.</p>
          <p>Deals 1066.66% of final ATK as damage.</p>
        </blockquote>
        <p>
          Arrives with a powerful screen wipe that has a high multiplier (no ATK
          buff, though). Then, for the next 10s, PrivM gains bonus Critical
          Damage and Attack Damage, which we identify as a rare buff that rarely
          gets diluted. But, don't celebrate too early as this 'awesomeness'
          belies its low value and is quite negligible seeing that it only
          triggers once every 40s (if no CDR). Thankfully, there is no gimmick
          nor other limitations to be cautious of.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Gameplay" />
        <p>
          As opposed to her convoluted arsenal, PrivM's gameplay is surprisingly
          very simple. You just want to land as many pellets as possible at all
          times and ensure she continuously shoots during Full Burst + 2s
          afterward without reloading for too long (outside SAnis's/Privaty's
          buff). You can easily achieve this by{' '}
          <strong>reloading before every Full Burst</strong>. Using Bastion and
          with Leona, you are guaranteed to achieve this milestone even at base
          ammo. Aim for optimal damage distance too, marked with a blue
          crosshair.
        </p>
        <Alert variant="primary">
          <p>
            Similar to XLud, to maximize her uptime, you want PrivM to only
            reload once every Full Burst. Since SG teams rarely have CDR, you
            want to make sure she can shoot up to 20s per rotation, minus reload
            time. With S2 and delay taken into consideration, she should take
            around 2s to reload, so 18s is your ultimate goal. If Tove is able
            to maintain full stacks for the whole 18s and thus retain the ATK
            Speed buff, a total of 38 shots can be fired within this window.
            Since you can recover 11 shots during Full Burst (ft. Leona), this
            effectively becomes 27. To be able to hit this milestone, you will
            need <strong>at least 1 Max Ammo OL + Bastion</strong>, with average
            luck with Tove. Should Tove lose stacks immediately after Full
            Burst, PrivM will immediately lose 6 ammo due to the reduced ammo
            cap. Do NOT rely on it too much!
          </p>
        </Alert>
        <p>
          Assuming you pair her with Tove and Leona, you want to ensure that
          Tove's stacks are at 3 before activating her burst skill. This would
          allow every SG unit to benefit from her maximum ATK buff for the
          entire Full Burst regardless of whether she loses any stacks
          thereafter. For optimal performance, nevertheless, you may want to RNG
          that Tove maintains her full stacks during Full Burst as this is
          everyone's primary DPS window.
        </p>
        <SectionHeader title="Usage" />
        <h6>Campaign</h6>
        <p>
          She can be thought of as a replica of Harran, who is used for her
          powerful screen wipe AOE attack at burst. PrivM can be thought of as
          an inferior replacement to Scarlet (who has a similar high damage AoE
          nuke at burst). She can perform surprisingly decent in the usual meta
          teams, but keep in mind that Maid Privaty will fall off heavily in
          high deficit campaign due to some major flaws. She has no self ATK
          buff outside burst like scarlet so her damage will fall off. This also
          means that she will stop screenclearing at high deficit. She cannot
          keep her attack buff stacked at all cuz she does not hit all of her
          pellets on enemies who are further away. To add to the last point,
          since she is not hitting all pellets her damage is reduced anyways,
          and her damage falls off even further due to less number of times her
          S1 is activated, hence she is reduced to having one utility only: Her
          AoE when she bursts, which is also weaker when compared to Scarlet's
          due to no self ATK buff.
        </p>
        <h6>Raids</h6>
        <p>
          Will probably see some usage in Solo Raid against bosses (like the
          current Solo Raid Boss: Crystal Chamber) with a giant body standing
          within SG's effective range. Superior against Water bosses. However,
          she needs Tove + Leona to even be worth investing.
        </p>
        <h6>Elysion Tower</h6>
        <p>
          PrivM's performance is mediocre in Elysion Tower due to being
          separated from Tove and Leona. Even so, her mediocrity is "great" for
          the current Elysion roster. Her screen wipe will be valuable in mob
          stages, but she will not perform well in boss stages due to the lack
          of Tove and Leona, which are usually the major wall in Elysion Tower.
          She can still benefit from 1 stack of ATK buff, which is better than
          nothing.
        </p>
        <h6>PVP</h6>
        <p>
          With a promising screen wipe if combined with Red Hood, XAnne, or
          both, offers an upgrade from Maiden's wipe in terms of pure damage.
          PrivM's Burst Skill boasts an astronomical multiplier that can easily
          benefit from ATK buffs. Sadly, she has a burst gen comparable to Poli
          or Naga, which can be unsightly. Regardless, she is remains to be a
          strong and viable choice for your 3rd team in PvP.
        </p>
        <p>
          <strong>Differences between PrivM & Maiden:</strong>
        </p>
        <ul>
          <li>
            PrivM brings a higher-damage nuke overall than Maiden's when paired
            with damage buffers.
          </li>
          <li>
            PrivM generates significantly less burst gen (comparable to Poli).
          </li>
          <li>
            PrivMy does not rely on hitcount to achieve maximum potential,
            unlike Maiden who needs to trigger S1.
          </li>
        </ul>
        <p>
          <strong>Thus, we can conclude two things:</strong>
        </p>
        <ul>
          <li>
            If burst gen is of utmost priority, and the hitcount requirement of
            Maiden's Skill 1 can be fulfilled, Maiden is a better choice.
          </li>
          <li>
            If a stronger nuke is needed, and lower burst gen is not a concern,
            go with PrivM.
          </li>
        </ul>
        <SectionHeader title="Investments" />
        <h5>Gear Investments</h5>
        <p>
          As explained in the Gameplay section above, you ideally want at least
          1 Max Ammo to maximize her uptime. However, this is not mandatory. It
          is in fact much better to aim for ATK and ELE lines, which provide
          significant contribution to damage. Acquire them as primary lines. For
          the secondary lines, you can aim for 1x Max Ammo or as much Hit
          Rate/CRIT Rate/CRIT Damage as possible. She can work with many kinds
          of lines, so she is quite RNG-friendly.
        </p>
        <h5>Skill Investments</h5>
        <ul>
          <li>
            <strong>Skill 1: 10</strong>
          </li>
          <ul>
            <li>
              Scales extremely well. Expect 1.3% DPS increase with each level,
              higher with Leona, topping out at 1.8%.
            </li>
          </ul>
        </ul>
        <ul>
          <li>
            <strong>Skill 2: 4-10</strong>
          </li>
          <ul>
            <li>
              Leave at 4 or 5 to save resources, otherwise 7 is acceptable for a
              minor damage increase. Expect around 0.6~0.7% DPS boost per level,
              which is quite low. This skyrockets if Full Burst extension by
              Modernia is used, in which case upgrading to 10 is desirable.
              Upgrading Tove's or Leona's skills first may bring more benefits.
            </li>
          </ul>
          <li>
            <strong>Burst: 4-7</strong>
          </li>
          <ul>
            <li>
              Effectiveness relies on CRIT Rate buffs the team can provide, but
              expect an average of 0.6~0.7% DPS boost as well per level. Leave
              at 4 or 5 to save resources. Upgrades are more impactful in Arena.
              Prioritize Tove's Burst Skill first in PvE.
            </li>
          </ul>
        </ul>
        <Alert variant="primary">
          <p>
            Dependent on Tove and Leona. Tove's S1 must be minimum Level 9,
            whereas Leona's S2 must be minimum Level 7. You also want Tove's
            Burst Skill and S2 to be adequately leveled as they provide
            significant damage multipliers, up to 10, as they scale extremely
            well.
          </p>
        </Alert>
        <h5>Cube Investments</h5>
        <StaticImage
          src="../../../images/nikke/generic/cube_4_sm.png"
          alt="Guide"
        />
        <p>
          Most of the time, you will want to use Bastion and reload before every
          Full Burst to guarantee no reload during the DPS window, in case Tove
          loses stacks immediately. With 1 Max Ammo OL, Bastion allows you to
          reach ideal uptime. With reload speed buffs (SAnis's/Tove's), you may
          be able to get away with Resilience.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="PVE Team Compositions" />
        <h5>Team #1 - SAnis Funneling Strategy</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tove" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="leona" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="sparkling-summer-anis"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="privaty-unkind-maid"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">Flex</div>
          </div>
        </div>
        <p>
          SAnis's damage has been tested to stay uniform even with Tove in team
          because her ATK and ATK Speed buffs compensate for fewer S2 triggers.
          In this team, nonetheless, she can enjoy Leona's increased Optimal
          Range and CRIT Rate while also buffing PrivM's overall output.
        </p>
        <h5>Team #2 - SAnis Free Strategy</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tove" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="leona" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="privaty-unkind-maid"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="sugar" enablePopover />
              <NikkeCharacter mode="icon" slug="maiden" enablePopover />
              <NikkeCharacter mode="icon" slug="guilty" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">Flex</div>
          </div>
        </div>
        <p>
          Often, bringing SAnis may not be the best idea. In such case, deploy
          one or more SG users that are properly invested. It is better to
          choose one who counters the element of the enemy, but for that you may
          need some ELE OL to make up for the kit disparity. Most of the time,
          you also need a healer, in which case Marciana or Helm can fit the
          bill for the FLEX spot. Otherwise, bring another DPS.
        </p>
        <h5>Team #3 - Full Burst Extension Strategy</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tove" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="leona" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="privaty-unkind-maid"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="modernia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">Flex</div>
          </div>
        </div>
        <p>
          In this team, Modernia uses her Burst Skill to extend Full Burst Time,
          so PrivM can enjoy her S2 ATK buffs longer. Modernia can also maintain
          maximum uptime as she reloads every time her Burst Skill ends.
          Requires 1 Max Ammo OL minimum.
        </p>
        <SectionHeader title="PVP Team Compositions" />
        <h5>Team #1 - Deceptive Rosanna Team</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="privaty-unkind-maid"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noir" enablePopover />
              <NikkeCharacter mode="icon" slug="sugar" enablePopover />
              <NikkeCharacter mode="icon" slug="drake" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rosanna" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="miracle-fairy-anne"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="anis" enablePopover />
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
            </div>
          </div>
        </div>
        <p>
          A team that can burst as fast as 2.5 RL if Rosanna's Sacrifice ability
          is triggered. This team revolves around sacrificing P1, which
          recharges Burst Gauge, and quickly activating Rosanna's Burst Skill to
          pick off key targets like Noise, Noah, or DPS units. Then, XAnne will
          revive as well as buff PrivM, which will in turn perform a full-screen
          AoE to finish off the rest.
        </p>
        <h5>Team #2 - Ordinary Wipe Team</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="privaty-unkind-maid"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="miracle-fairy-anne"
                enablePopover
              />
              <NikkeCharacter mode="icon" slug="blanc" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="anis" enablePopover />
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="jackal" enablePopover />
            </div>
          </div>
        </div>
        <p>
          A slower version of Maiden's variant which has a more powerful wipe
          but is more counterable by Noise/Noah due to the reduced burst gen.
        </p>
        <h5>Team #3 - Backline Pressure Team</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noise" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="pepper" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="anis" enablePopover />
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="a2" enablePopover />
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
              <NikkeCharacter
                mode="icon"
                slug="sparkling-summer-anis"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="privaty-unkind-maid"
                enablePopover
              />
            </div>
          </div>
        </div>
        <p>
          A defense team in which PrivM does not burst. Here, her objective is
          to apply backline pressure with the help of her Skill 1, which hits P4
          and also P5. It is recommended to bring frontline or teamwide pressure
          DPS in P3. This will quickly consume the attacker's Biscuit immunity
          charges or force them to protect their backline with Makima, lowering
          burst gen. Other units should provide stalling potential (like Noah,
          Biscuit, Moran). RH can be used as a support to buff primary B3 and
          PrivM's auto-attack. Counterable by fast-nuke teams.
        </p>
        <SectionHeader title="Should You Pull" />
        <p>
          Not that meta-changing right now, but if another unit is released to
          cover up SG teams' major weaknesses, PrivM can become more
          influential. Worth pulling/investing only if you have and can invest
          in Tove and Leona. If that applies to you, just wishlist or perhaps
          pull one if you love gambling.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Pros & Cons" />
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Utilizes Tove & Leona's supportive buffs effectively, which
                    other units don't hog.
                  </li>
                  <li>Promising Elysion DPS.</li>
                  <li>Compatible with many OL lines.</li>
                  <li>
                    Good AoE screen wipe on burst, similar to Harran's and
                    better than Maiden's for PvP.
                  </li>
                  <li> Waifu material.</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Quite expensive and requires investments on Tove/Leona.
                  </li>
                  <li> Damage is not that impressive if ALONE.</li>
                  <li>
                    Restrictive gameplay; can be severely underwhelming in most
                    cases.
                  </li>
                  <li>Low burst gen restricts PvP team building.</li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Final ratings" />
        <div className="ratings-container">
          <div className="ratings-box a">
            <h6>A</h6>
            <p>Story (High Deficit)</p>
          </div>
          <div className="ratings-box ss">
            <h6>SS</h6>
            <p>Story (Low Deficit)</p>
          </div>
          <div className="ratings-box s">
            <h6>S</h6>
            <p>Boss (Solo)</p>
          </div>
          <div className="ratings-box s">
            <h6>S</h6>
            <p>Boss (Adds)</p>
          </div>
          <div className="ratings-box s">
            <h6>S</h6>
            <p>PVP</p>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesPrivatyMaid;

export const Head: React.FC = () => (
  <Seo
    title="Privaty: Unkind Maid guide & review | NIKKE | Prydwen Institute"
    description="A guide & review for Privaty: Unkind Maid in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
